<template>
  <!-- Static content like app-bar -->

  <h1 class="site-title">DJ Chris</h1>

  <!-- Dynamic content for routes -->
  <div>
    <!-- Search Bar -->
    <div class="search-bar">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search songs..."
        class="search-input"
      />
    </div>

    <!-- Body -->
    <div class="content">
      <div v-if="filteredSongs.length === 0" class="no-songs-found"></div>

      <!-- Songs Grid -->
      <div class="grid-container">
        <div
          v-for="song in filteredSongs"
          :key="song.id"
          class="grid-item"
          @click="goToSongDetails(song)"
        >
          <div class="thumbnail">
            <img
              :src="getThumbnailUrl(song.thumbnail_name)"
              :alt="song.title"
              class="thumbnail-image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { supabase } from "../lib/supabaseClient";

export default {
  setup() {
    const songs = ref([]); // Stores all fetched songs
    const searchQuery = ref(""); // Reactive property for the search input
    const router = useRouter();

    // Fetch songs on component mount
    onMounted(() => {
      document.body.style.backgroundColor = "#1A1A1D";
      getSongs();
    });

    // Fetch songs from the database
    async function getSongs() {
      const { data, error } = await supabase.from("songs").select();
      if (error) {
        console.error("Error fetching songs:", error);
      } else {
        songs.value = data; // Populate the songs array with fetched data
      }
    }

    // Generate public thumbnail URL
    function getThumbnailUrl(thumbnailName) {
      const response = supabase.storage
        .from("thumbnails")
        .getPublicUrl(thumbnailName);

      return response.data["publicUrl"];
    }

    // Navigate to song details
    function goToSongDetails(song) {
      if (router) {
        router.push({ name: "song-details", params: { songId: song.id } });
      } else {
        console.error("Router is not initialized");
      }
    }

    const filteredSongs = computed(() => {
      const songsCopy = [...songs.value]; // Create a copy to avoid mutating the original array
      const sortedSongs = songsCopy.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      if (searchQuery.value) {
        return sortedSongs.filter((song) =>
          song.song_title
            ?.toLowerCase()
            .includes(searchQuery.value.toLowerCase())
        );
      }

      return sortedSongs;
    });

    return {
      songs,
      searchQuery,
      filteredSongs,
      getThumbnailUrl,
      goToSongDetails,
    };
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  background-color: #1a1a1d; /* Add a background color to see the grid */
}

.grid-item {
  cursor: pointer;
  transition: transform 0.3s;
  background-color: #1a1a1d;
  padding: 0; /* Remove padding so the image fills the space */
  overflow: hidden; /* Ensure the image does not overflow the grid item */
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the grid item */
}
.search-bar {
  margin: 16px;
  text-align: center;
}

.search-input {
  width: 80%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.search-input:focus {
  border-color: #ff6600;
}
.site-title {
  text-align: center; /* Centers text inside the h1 */
  color: white;
}
.no-songs-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: white;
}
</style>
